import React from 'react'

function CommonCourseComponents({degree}) {
  if (degree) {
    switch (degree) {
      case "Bachelor’s Degree":
      case "4":
        return <div className="degree-type degree-bachelor">
          <span title="name" className="name"><i className="fas fa-graduation-cap" />Bachelor’s Degree</span>
        </div>
      case "Higher Certificate":
      case "3":
        return <div className="degree-type degree-higher">
          <span title="name" className="name"><i className="fas fa-file-certificate" />higher certificate</span>
        </div>
      case "Diploma":
      case "2":
        return <div className="degree-type degree-diploma">
          <span title="name" className="name"><i className="fas fa-certificate" />diploma</span>
        </div>
      case "National Certificate":
      case "1":
      case "Certificate":
        return <div className="degree-type degree-national">
          <span title="name" className="name"><i className="fas fa-award" />national certificate</span>
        </div>
      case "Master Certificate":
        return <div className="degree-type degree-master">
          <span title="name" className="name"><i className="fas fa-university" />master certificate</span>
        </div>
      case "Occupational Certificate":
        return <div className="degree-type degree-occupational">
          <span title="name" className="name">occupational certificate</span>
        </div>
      case "Honours Degree":
        case "5" :
        return <div className="degree-type degree-honours">
          <span title="name" className="name"><i className="fas fa-medal" />honours Degree</span>
        </div>
      case "Learning Path":
      case "7":
        return <div className="degree-type degree-learning">
          <span title="name" className="name"><i className="fas fa-route" />learning path</span>
        </div>
      case "Master’s Degree":
        return <div className="degree-type degree-master">
          <span title="name" className="name"><i className="fas fa-university" />Master’s Degree</span>
        </div>
      default:
        return <div className="degree-type degree-learning">
        <span title="name" className="name">N/A</span>
      </div>
    }
  }
  else {
    return null
  }
}
export default CommonCourseComponents


export function ProgramIcons({ degree }) {
  if (degree) {
    switch (degree) {
      case "Bachelor’s Degree":
      case "4":
        return <i className="fas fa-graduation-cap" />
      case "Higher Certificate":
      case "3":
        return <i className="fas fa-file-certificate" />
      case "Diploma":
      case "2":
        return <i className="fas fa-certificate" />
      case "National Certificate":
      case "1":
      case "Certificate":
        return <i className="fas fa-award" />
      case "Master Certificate":
        return <i className="fas fa-university" />
      case "Occupational Certificate":
        return <i className="fas fa-user-graduate" />
      case "Honours Degree":
      case "5":
        return <i className="fas fa-medal" />
      case "Learning Path":
      case "7":
        return <i className="fas fa-route" />
      case "Master’s Degree":
        return <i className="fas fa-university" />
      default:
        return "N/A"
    }
  }
  else {
    return null
  }
}