import React, { useEffect, useRef, useState } from 'react';
import { TrimText } from '../common/TrimText';
import { InitalRender } from '../common/Helper';
import { ColorRender } from '../ticket/StatusRender';
import { BASE_URL } from '../../utils/Constants';

function ViewProfile({ user, cardParent,ele }) {
    const [isToggled, setIsToggled] = useState(false);
    const divRef = useRef(null);

    const handleButtonClick = (event) => {
        event.preventDefault();
        setIsToggled(!isToggled);
    };

    const handleClickOutside = (event) => {
        if (divRef.current && !divRef.current.contains(event.target)) {
            setIsToggled(false);
        }
    };

    const handleViewProfile = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    useEffect(() => {
        // Add event listener for clicks outside of the component
        document.addEventListener('mousedown', handleClickOutside);
        
        // Cleanup event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div ref={divRef} onClick={(e)=>e.preventDefault()}> 
            <div className="assigned-title-itm lecturer-profile support-team-status-div" onClick={handleViewProfile}>
                <span
                    className={
                        "name-icon cat-dark-red " +
                        `${user.prog_lead_photo ? 'transparentBg' : ColorRender(user?.category)}` +
                        " user-drop-down-img"
                    }
                    style={{ fontSize: "14px" }}
                >
                    {user.prog_lead_photo ? (
                        <img
                            src={
                                BASE_URL +
                                "/" +
                                user.prog_lead_photo.replace("/home/myaie/public_html/", "")
                            }
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.closest('.name-icon.cat-dark-red').append(InitalRender(user.prog_lead_name));
                                currentTarget.remove();
                            }}
                        />
                    ) : (
                        InitalRender(user.prog_lead_name)
                    )}
                    {user?.prog_lead_status === "Offline" ? (
                        <span className="portal-status-icon offline-u"></span>
                    ) : (
                        <span className="portal-status-icon online-u"></span>
                    )}
                </span>
                <div className="overflow-ellipsis2" title={user?.prog_lead_name}>
                    {TrimText(user.prog_lead_name ? user.prog_lead_name : "NA", !((ele?.fileName && ele?.file) || ele?.timeTable) ? 200 : 15)}
                </div>
                {user.supp_fullname && (
                    <span className="more-view" id='view-team' onClick={handleButtonClick}>+1</span>
                )}
            </div>
            
            {isToggled && (
                <div className={`support-team-profile ${!cardParent ? "parent-team-profile" : ""}`}>
                <div className='leacture-supportor-profile'>
                        <div>
                            <p className='team'>{cardParent ? "Programme Support" : "Lecturer"}</p>
                            <article>
                                <figure className={`${(user?.supp_photo && user?.supp_photo == "") ? 'transparentBg' : ColorRender(user?.category)} team-detail`}>
                                    {user?.supp_photo ? (
                                        <img
                                            src={
                                                BASE_URL +
                                                "/" +
                                                user?.supp_photo.replace("/home/myaie/public_html/", "")
                                            }
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.closest('figure').append(InitalRender(user?.supp_fullname));
                                                currentTarget.remove();
                                            }}
                                        />
                                    ) : (
                                        InitalRender(user?.supp_fullname)
                                    )}
                                    {user?.supp_activity_status === "Offline" ? (
                                        <span className="portal-status-icon offline-u"></span>
                                    ) : (
                                        <span className="portal-status-icon online-u"></span>
                                    )}
                                </figure>
                                <figcaption>
                                    <h4 title={user?.supp_fullname}>
                                        {/* {user?.supp_fullname ? user?.supp_fullname : "NA"} */}
                                        {TrimText(user?.supp_fullname ? user.supp_fullname : "NA", 15)}
                                    </h4>
                                </figcaption>
                            </article>
                        </div>

                        <div>
                            <p className='team'>{cardParent ? "Programme Coordinator" : "Support"}</p>
                            <article>
                                <figure className={`${(user?.lead_photo && user?.lead_photo == "") ? 'transparentBg' : ColorRender(user?.category)} team-detail`}>
                                    {user.lead_photo ? (
                                        <img
                                            src={
                                                BASE_URL +
                                                "/" +
                                                user?.lead_photo.replace("/home/myaie/public_html/", "")
                                            }
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.closest('figure').append(InitalRender(user.prog_lead_name));
                                                currentTarget.remove();
                                            }}
                                        />
                                    ) : (
                                        InitalRender(user.prog_lead_name)
                                    )}
                                    {user?.prog_lead_status === "Offline" ? (
                                        <span className="portal-status-icon offline-u"></span>
                                    ) : (
                                        <span className="portal-status-icon online-u"></span>
                                    )}
                                </figure>
                                <figcaption>
                                    <h4 title={user?.prog_lead_name}>
                                        {/* {user?.prog_lead_name ? user?.prog_lead_name : "NA"} */}
                                        {TrimText(user?.prog_lead_name ? user?.prog_lead_name : "NA", 15)}
                                    </h4>
                                </figcaption>
                            </article>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ViewProfile;
