import moment from 'moment';
import $ from "jquery";
import IconProgress from '../assets/images/In-Progress.png'
import IconNotStarted from '../assets/images/Not-Started.png';
import IconNotPublished from '../assets/images/Not-Published.png';
import IconFinished from '../assets/images/Finished.png';
import IconSignOff from '../assets/images/Pending-Sign-Off.png';
//import moment from 'moment-timezone';
//moment.tz.setDefault("Africa/Johannesburg");
import axios from 'axios';
import { DOWNLOAD_FILE_URI, STUDENT_FILE_URI, zipURL } from './Constants';
import Str from '../components/common/Str';


export function userTagColor(comment_user, login_user){
    if (comment_user == login_user) {
      return " my-tag ";
    } else {
      return " other-tag ";
    }
};

export function shortenText(content,showChar) {  
    let text=content;
    if(content?.length > showChar) {
      let c = content.substr(0, showChar);
      var lastIndex = c.lastIndexOf(" ");
      c = c.substring(0, lastIndex);
      text = c+"...";
    }
   return text;
}

export function getSubjectStatus(status) {  
    switch(status) {
        case 'In Progress' :
            var icon = IconProgress
            break
        case 'Not Started' :
            var icon = IconNotStarted
            break
        case 'Finished' :
            var icon = IconFinished
            break;
        case 'Pending-Sign off' :
            var icon = IconSignOff
            break;
        case 'Pending Review' :
            var icon = IconSignOff
            break;
        default :
            var icon = IconNotPublished
            break;
  }
 return icon;
}

export function subjectDuration(start,end) {
    let startDate=moment(start,"Do MMM YY");
    let endDate=moment(end,"Do MMM YY");

    let years = endDate.diff(startDate, 'year');
    startDate.add(years, 'years');

    let months = endDate.diff(startDate, 'months');
    startDate.add(months, 'months');

    let days = endDate.diff(startDate, 'days') + 1;
    let returnDate='(';
    if(years>0){
        returnDate+=years + 'y, ';
    }
    returnDate+=months + 'm, ';
    returnDate+=days + 'd)';
    return returnDate;
}

export function calculateDuration(start, end){
    const startMoment = moment.unix(start);
    const endMoment = moment.unix(end);
    const duration = moment.duration(endMoment.diff(startMoment));
  
    const years = Math.floor(duration.asYears());
    const months = Math.floor(duration.asMonths()) % 12;
    const days = Math.floor(duration.asDays()) % 30;
  
    const parts = [];
    if (years > 0) parts.push(`${years}${years > 0 ? 'y' : ''}`);
    if (months > 0) parts.push(`${months}${months > 0 ? 'm' : ''}`);
    if (days > 0) parts.push(`${days}${days > 0 ? 'd' : ''}`);
  
    return parts.join(' ');
  };

const calculateAccurateDuration = (startMoment, endMoment) => {
    const years = endMoment.diff(startMoment, 'years');
    startMoment.add(years, 'years');

    const months = endMoment.diff(startMoment, 'months');
    startMoment.add(months, 'months'); 

    const days = endMoment.diff(startMoment, 'days');

    return `${years}y, ${months}m, ${days}d`;
};

export const formatDateRange = (start, end) => {
    if (!start || !end) return "Invalid Dates";

    const startMoment = moment.unix(start);
    const endMoment = moment.unix(end);

    const formattedStart = startMoment.format(Str.TABLE_DATE_FORMAT);
    const formattedEnd = endMoment.format(Str.TABLE_DATE_FORMAT);

    const duration = calculateAccurateDuration(startMoment, endMoment);
console.log("`${formattedStart} to ${formattedEnd} (${duration})`",`${formattedStart} to ${formattedEnd} (${duration})`)
    return `${formattedStart} to ${formattedEnd} (${duration})`;
};


export function subjectDurationUnix(start,end) {
    let startDate=moment.unix(start);
    let endDate=moment.unix(end);

    let years = endDate.diff(startDate, 'year');
    startDate.add(years, 'years');

    let months = endDate.diff(startDate, 'months');
    startDate.add(months, 'months');

    let days = endDate.diff(startDate, 'days');
    let returnDate='(';
    if(years>0){
        returnDate+=years + 'y, ';
    }
    returnDate+=months + 'm, ';
    returnDate+=days + 'd)';
    return returnDate;
}

export function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}

export function getRandomOrder(max) {
    let randomNum = Math.floor(Math.random() * Math.floor(max));
    while (randomNum === max) {
        randomNum = Math.floor(Math.random() * Math.floor(max));
    }
    return randomNum;
}

export function capitalize(s) {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export function nationalityList() {
    let Obj={
        'zar' : 'South Africa',
        'bwa' : 'Botswana',
        'lso' : 'Lesotho',
        'moz' : 'Mozambique',
        'nam' : 'Namibia',
        'swz' : 'Swaziland',
        'zmb' : 'Zambia',
        'zwe' : 'Zimbabwe',
        'afg' : 'Afghanistan',
        'alb' : 'Albania',
        'dza' : 'Algeria',
        'and' : 'Andorra',
        'ago' : 'Angola',
        'arg' : 'Argentina',
        'aus' : 'Australia',
        'aut' : 'Austria',
        'aze' : 'Azerbaijan',
        'bdi' : 'Burundi',
        'bel' : 'Belgium',
        'ben' : 'Benin',
        'bfa' : 'Burkina Faso',
        'bgd' : 'Bangladesh',
        'bgr' : 'Bulgaria',
        'bhr' : 'Bahrain',
        'bhs' : 'Bahamas',
        'bih' : 'Bosnia and Herzegovina',
        'blr' : 'Belarus',
        'bmu' : 'BMU',
        'bol' : 'Bolivia',
        'bra' : 'Brazil',
        'btn' : 'Bhutan',
        'caf' : 'Central African Republic',
        'can' : 'Canada',
        'chl' : 'Chile',
        'chn' : 'China',
        'civ' : 'Cote D Ivoire',
        'cmr' : 'Cameroon',
        'cod' : 'Demogratic Republic of the Congo',
        'col' : 'Colombia',
        'com' : 'Comoros',
        'cub' : 'Cuba',
        'cyp' : 'Cyprus',
        'cze' : 'Czech Republic',
        'deu' : 'Germany',
        'dji' : 'Djibouti',
        'dma' : 'Dominica',
        'dnk' : 'Denmark',
        'dom' : 'Dominican Republic',
        'lbr' : 'Liberia',
        'lby' : 'Libyan Arab Jamahiriya',
        'lca' : 'Saint Lucia',
        'lka' : 'Sri Lanka',
        'ltu' : 'Lithuania',
        'lux' : 'Luxembourg',
        'lva' : 'Latvia',
        'mar' : 'Morocco',
        'mco' : 'Monaco',
        'mda' : 'Republic of Moldova',
        'mdg' : 'Madagascar',
        'mdv' : 'Maldives',
        'mex' : 'Mexico',
        'mkd' : 'Macedonia',
        'mli' : 'Mali',
        'mlt' : 'Malta',
        'mmr' : 'Myanmar',
        'mrt' : 'Mauritania',
        'mus' : 'Mauritius',
        'mwi' : 'Malawi',
        'mys' : 'Malaysia',
        'ner' : 'Niger',
        'nga' : 'Nigeria',
        'nic' : 'Nicaragua',
        'nld' : 'Netherlands',
        'nor' : 'Norway',
        'npl' : 'Nepal',
        'nzl' : 'New Zealand',
        'omn' : 'Oman',
        'pak' : 'Pakistan',
        'pan' : 'Panama',
        'per' : 'Peru',
        'phl' : 'Philippines',
        'png' : 'Papua New Guinea',
        'pol' : 'Poland',
        'prk' : 'Democratic Peoples Republic of  Korea',
        'prt' : 'Portugal',
        'pry' : 'Paraguay',
        'pse' : 'Palestine',
        'qat' : 'Qatar',
        'rou' : 'Romania',
        'rus' : 'Russian Federation',
        'egy' : 'Egypt',
        'eri' : 'Eritrea',
        'esp' : 'Spain',
        'est' : 'Estonia',
        'eth' : 'Ethiopia',
        'fin' : 'Finland',
        'fji' : 'Fiji',
        'fra' : 'France',
        'gab' : 'Gabon',
        'gbr' : 'United Kingdom of Great Britain and Northern Ireland',
        'gha' : 'Ghana',
        'gin' : 'Guinea',
        'gmb' : 'Gambia',
        'gnb' : 'Guinea-Bissau',
        'gnq' : 'Equatorial Guinea',
        'grc' : 'Greece',
        'guy' : 'Guyana',
        'hkg' : 'Hong Kong',
        'hnd' : 'Honduras',
        'hrv' : 'Croatia',
        'hti' : 'Haiti',
        'hun' : 'Hungary',
        'idn' : 'Indonesia',
        'ind' : 'India',
        'irl' : 'Ireland',
        'irn' : 'Iran',
        'irq' : 'Iraq',
        'isl' : 'Iceland',
        'isr' : 'Israel',
        'ita' : 'Italy',
        'jam' : 'Jamaica',
        'jor' : 'Jordan',
        'jpn' : 'Japan',
        'kaz' : 'Kazakhstan',
        'ken' : 'Kenya',
        'kgz' : 'Kyrgyzstan',
        'kor' : 'Republic of Korea',
        'kwt' : 'Kuwait',
        'lbn' : 'Lebanon',
        'che' : 'Switzerland',
        'rwa' : 'Rwanda',
        'sau' : 'Saudi Arabia',
        'sdn' : 'Sudan',
        'sen' : 'Senegal',
        'sgp' : 'Singapore',
        'slb' : 'Solomon Islands',
        'sle' : 'Sierra Leone',
        'slv' : 'El Salvador',
        'som' : 'Somalia',
        'srb' : 'Serbia',
        'syc' : 'Seychelles',
        'sur' : 'Suriname',
        'svk' : 'Slovakia',
        'svn' : 'Slovenia',
        'swe' : 'Sweden',
        'syr' : 'Syrian Arab Republic',
        'tcd' : 'Chad',
        'tgo' : 'Togo',
        'tha' : 'Thailand',
        'tjk' : 'Tajikistan',
        'tto' : 'Trinidad and Tobago',
        'tun' : 'Tunisia',
        'tur' : 'Turkey',
        'twn' : 'Taiwan',
        'tza' : 'United Republic of Tanzania',
        'uga' : 'Uganda',
        'ukr' : 'Ukraine',
        'ury' : 'Uruguay',
        'are' : 'United Arab Emirates',
        'usa' : 'United States of America',
        'uzb' : 'Uzbekistan',
        'vct' : 'Saint Vincent and the Grenadines',
        'ven' : 'Venezuela',
        'vnm' : 'Vietnam',
        'yem' : 'Yemen',
        'yug' : 'Yogoslavia',
        'other' : 'Other',
    }
    return Obj;
}
export function disabilityList() {
    let Obj={
        'none'        : 'No disibility specified',
        'albinism'      : 'Albinism',
        'adhd'        : 'Attention deficit disorder with/without hyperactivity (ADHD)',
        'autism'        : 'Autistic spectrum disorders',
        'behavioural'     : 'Behavioural / conduct disorder (including Sever behavioural problems)',
        'blindness'       : 'Blindness',
        'cerebral_palsy'    : 'Cerebral palsy',
        'deafness'      : 'Deafness',
        'dear_blindness'    : 'Deaf-blindness',
        'epilepsy'      : 'Epilepsy',
        'hard_hearing'    : 'Hard of hearing',
        'mild_intellectual_disability' : 'Mild to moderate intellectual disability',
        'moderate_intellectual_disabiltiy' : 'Moderate to severe/profound intellectual disability',
        'partial_sightedness' : 'Partial sightedness / Low Vision',
        'physical_disability' : 'Physical disabiity',
        'sever_intellectual_disability' : 'Severely intellectually disabled',
        'learning_disability' : 'Specific learning disability',
        'psychiatric_disorder' : 'Psychiatric disorder',
    }
    return Obj;
}
export function languageList() {
    let Obj={
        'english'        : 'English',
        'sepedi'      : 'Sepedi',
        'sesotho'    : 'Sesotho',
        'setswana'      : 'Setswana',
        'siSwati'      : 'siSwati',
        'tshivenda'      : 'Tshivenda',
        'xitsonga'      : 'Xitsonga',
        'afrikaans'      : 'Afrikaans',
        'isiNdebele'      : 'isiNdebele',
        'isiXhosa'        : 'isiXhosa',
        'isiZulu'      :'isiZulu',

    }
    return Obj;
}
export function loadWidgetChat(liveuser, email, userID) {
    window.LHCChatOptions = {};
    window.LHCChatOptions.opt = {
      widget_height: 448,
      widget_width: 360,
      popup_height: 520,
      popup_width: 500,
    };
    (function () {
      let po = document.createElement("script");
      po.type = "text/javascript";
      po.async = true;
      let referrer = document.referrer
        ? encodeURIComponent(
            document.referrer.substr(document.referrer.indexOf("://") + 1)
          )
        : "";
      let location = document.location
        ? encodeURIComponent(
            window.location.href.substring(window.location.protocol.length)
          )
        : "";
      //"//www.livehelper.conceptinteractive.co.za/index.php/chat/getstatus/(click)/internal/(position)/bottom_right/(ma)/br/(top)/350/(units)/pixels/(leaveamessage)/true?r=" +

      po.src =
        "https://live.myaie.ac/index.php/chat/getstatus/(click)/internal/(position)/bottom_right/(ma)/br/(top)/350/(units)/pixels/(leaveamessage)/true?r=" +
        referrer +
        "&l=" +
        location +
        "&username=" +
        liveuser +
        "&email=" +
        email +
        "&userID=" +
        userID;
      let s = document.getElementsByTagName("script")[0];

      s.parentNode.insertBefore(po, s);

      // setTimeout(function () {
      //   s.parentNode.insertBefore(po, s);
      // }, 5000);
    })();
}

export const checkUserKeys = ["first_name","last_name","title","number","bday_day","bday_month","bday_year","race","nationality","disability","gender","language"]

export const secToMin = (value) => {
    var timestamp = value;
    var hours = Math.floor(timestamp / 60 / 60);
    var minutes = Math.floor(timestamp / 60) - hours * 60;
    var seconds = timestamp % 60;
    // hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
    var formatted =
      minutes.toString().padStart(2, "0") +
      ":" +
      seconds.toString().padStart(2, "0");
    return formatted;
  };

export const FetchFileIcon = (path) => {
    // console.log("___________ FILE PATH:",path)
    let ext = path?.toString()?.split('.');
    switch (ext[ext.length - 1]) {
        case 'doc':
        case 'docx':
            return 'fal fa-file-word';
        case 'zip':
            return 'fal fa-file-archive';
        case 'pdf':
            return 'fal fa-file-pdf';
        case 'xls':
        case 'xlsx':
            return 'fal fa-file-excel';
        case 'jpg':
        case 'jpeg':
        case 'png':
            return 'fal fa-file-image';
        case 'txt':
            return 'fal fa-file-alt';
        case 'ppt':
        case 'pptx':
            return 'fal fa-file-powerpoint';
        default:
            return 'fal fa-file';
    }
}
const bytesToKB = (bytes) => {
    return bytes / 1024;
}

export const GetFileSize = async (url) => {
    let size = '0KB';
    if (url){
        //remove http:// or https:// from url if it has it
        url = url.replace(/(^\w+:|^)\/\//, '');
      }
    let fileResponse = await axios.head(url.includes(STUDENT_FILE_URI) ? url.replace(STUDENT_FILE_URI, DOWNLOAD_FILE_URI) : zipURL + url)
    const fileSize = fileResponse.headers["content-length"] || 0
    size = `${bytesToKB(Number(fileSize)).toFixed(2)}KB`;
    return size;
    
}

export const twoDecimalRound = (num) => {
    return Math.round((Number(num) + Number.EPSILON) * 100) / 100
}

export const languageListOptions = [
    {value:'english',label:'English'},
    {value:'sepedi',label:'Sepedi'},
    {value:'sesotho',label:'Sesotho'},
    {value:'setswana',label:'Setswana'},
    {value:'siSwati',label:'siSwati'},
    {value:'tshivenda',label :'Tshivenda'},
    {value:'xitsonga',label:'Xitsonga'},
    {value:'afrikaans',label:'Afrikaans'},
    {value:'isiNdebele',label:'isiNdebele'},
    {value:'isiXhosa',label:'isiXhosa'},
    {value:'isiZulu',label:'isiZulu'},
]

export const nationalityListOptions = [    
    {value: 'zar', label: 'South Africa'},
    {value: 'bwa', label: 'Botswana'},
    {value: 'lso', label: 'Lesotho'},
    {value: 'moz', label: 'Mozambique'},
    {value: 'nam', label: 'Namibia'},
    {value: 'swz', label: 'Swaziland'},
    {value: 'zmb', label: 'Zambia'},
    {value: 'zwe', label: 'Zimbabwe'},
    {value: 'afg', label: 'Afghanistan'},
    {value: 'alb', label: 'Albania'},
    {value: 'dza', label: 'Algeria'},
    {value: 'and', label: 'Andorra'},
    {value: 'ago', label: 'Angola'},
    {value: 'arg', label: 'Argentina'},
    {value: 'aus', label: 'Australia'},
    {value: 'aut', label: 'Austria'},
    {value: 'aze', label: 'Azerbaijan'},
    {value: 'bdi', label: 'Burundi'},
    {value: 'bel', label: 'Belgium'},
    {value: 'ben', label: 'Benin'},
    {value: 'bfa', label: 'Burkina Faso'},
    {value: 'bgd', label: 'Bangladesh'},
    {value: 'bgr', label: 'Bulgaria'},
    {value: 'bhr', label: 'Bahrain'},
    {value: 'bhs', label: 'Bahamas'},
    {value: 'bih', label: 'Bosnia and Herzegovina'},
    {value: 'blr', label: 'Belarus'},
    {value: 'bmu', label: 'BMU'},
    {value: 'bol', label: 'Bolivia'},
    {value: 'bra', label: 'Brazil'},
    {value: 'btn', label: 'Bhutan'},
    {value: 'caf', label: 'Central African Republic'},
    {value: 'can', label: 'Canada'},
    {value: 'chl', label: 'Chile'},
    {value: 'chn', label: 'China'},
    {value: 'civ', label: 'Cote D Ivoire'},
    {value: 'cmr', label: 'Cameroon'},
    {value: 'cod', label: 'Demogratic Republic of the Congo'},
    {value: 'col', label: 'Colombia'},
    {value: 'com', label: 'Comoros'},
    {value: 'cub', label: 'Cuba'},
    {value: 'cyp', label: 'Cyprus'},
    {value: 'cze', label: 'Czech Republic'},
    {value: 'deu', label: 'Germany'},
    {value: 'dji', label: 'Djibouti'},
    {value: 'dma', label: 'Dominica'},
    {value: 'dnk', label: 'Denmark'},
    {value: 'dom', label: 'Dominican Republic'},
    {value: 'lbr', label: 'Liberia'},
    {value: 'lby', label: 'Libyan Arab Jamahiriya'},
    {value: 'lca', label: 'Saint Lucia'},
    {value: 'lka', label: 'Sri Lanka'},
    {value: 'ltu', label: 'Lithuania'},
    {value: 'lux', label: 'Luxembourg'},
    {value: 'lva', label: 'Latvia'},
    {value: 'mar', label: 'Morocco'},
    {value: 'mco', label: 'Monaco'},
    {value: 'mda', label: 'Republic of Moldova'},
    {value: 'mdg', label: 'Madagascar'},
    {value: 'mdv', label: 'Maldives'},
    {value: 'mex', label: 'Mexico'},
    {value: 'mkd', label: 'Macedonia'},
    {value: 'mli', label: 'Mali'},
    {value: 'mlt', label: 'Malta'},
    {value: 'mmr', label: 'Myanmar'},
    {value: 'mrt', label: 'Mauritania'},
    {value: 'mus', label: 'Mauritius'},
    {value: 'mwi', label: 'Malawi'},
    {value: 'mys', label: 'Malaysia'},
    {value: 'ner', label: 'Niger'},
    {value: 'nga', label: 'Nigeria'},
    {value: 'nic', label: 'Nicaragua'},
    {value: 'nld', label: 'Netherlands'},
    {value: 'nor', label: 'Norway'},
    {value: 'npl', label: 'Nepal'},
    {value: 'nzl', label: 'New Zealand'},
    {value: 'omn', label: 'Oman'},
    {value: 'pak', label: 'Pakistan'},
    {value: 'pan', label: 'Panama'},
    {value: 'per', label: 'Peru'},
    {value: 'phl', label: 'Philippines'},
    {value: 'png', label: 'Papua New Guinea'},
    {value: 'pol', label: 'Poland'},
    {value: 'prk', label: 'Democratic Peoples Republic of  Korea'},
    {value: 'prt', label: 'Portugal'},
    {value: 'pry', label: 'Paraguay'},
    {value: 'pse', label: 'Palestine'},
    {value: 'qat', label: 'Qatar'},
    {value: 'rou', label: 'Romania'},
    {value: 'rus', label: 'Russian Federation'},
    {value: 'egy', label: 'Egypt'},
    {value: 'eri', label: 'Eritrea'},
    {value: 'esp', label: 'Spain'},
    {value: 'est', label: 'Estonia'},
    {value: 'eth', label: 'Ethiopia'},
    {value: 'fin', label: 'Finland'},
    {value: 'fji', label: 'Fiji'},
    {value: 'fra', label: 'France'},
    {value: 'gab', label: 'Gabon'},
    {value: 'gbr', label: 'United Kingdom of Great Britain and Northern Ireland'},
    {value: 'gha', label: 'Ghana'},
    {value: 'gin', label: 'Guinea'},
    {value: 'gmb', label: 'Gambia'},
    {value: 'gnb', label: 'Guinea-Bissau'},
    {value: 'gnq', label: 'Equatorial Guinea'},
    {value: 'grc', label: 'Greece'},
    {value: 'guy', label: 'Guyana'},
    {value: 'hkg', label: 'Hong Kong'},
    {value: 'hnd', label: 'Honduras'},
    {value: 'hrv', label: 'Croatia'},
    {value: 'hti', label: 'Haiti'},
    {value: 'hun', label: 'Hungary'},
    {value: 'idn', label: 'Indonesia'},
    {value: 'ind', label: 'India'},
    {value: 'irl', label: 'Ireland'},
    {value: 'irn', label: 'Iran'},
    {value: 'irq', label: 'Iraq'},
    {value: 'isl', label: 'Iceland'},
    {value: 'isr', label: 'Israel'},
    {value: 'ita', label: 'Italy'},
    {value: 'jam', label: 'Jamaica'},
    {value: 'jor', label: 'Jordan'},
    {value: 'jpn', label: 'Japan'},
    {value: 'kaz', label: 'Kazakhstan'},
    {value: 'ken', label: 'Kenya'},
    {value: 'kgz', label: 'Kyrgyzstan'},
    {value: 'kor', label: 'Republic of Korea'},
    {value: 'kwt', label: 'Kuwait'},
    {value: 'lbn', label: 'Lebanon'},
    {value: 'che', label: 'Switzerland'},
    {value: 'rwa', label: 'Rwanda'},
    {value: 'sau', label: 'Saudi Arabia'},
    {value: 'sdn', label: 'Sudan'},
    {value: 'sen', label: 'Senegal'},
    {value: 'sgp', label: 'Singapore'},
    {value: 'slb', label: 'Solomon Islands'},
    {value: 'sle', label: 'Sierra Leone'},
    {value: 'slv', label: 'El Salvador'},
    {value: 'som', label: 'Somalia'},
    {value: 'srb', label: 'Serbia'},
    {value: 'syc', label: 'Seychelles'},
    {value: 'sur', label: 'Suriname'},
    {value: 'svk', label: 'Slovakia'},
    {value: 'svn', label: 'Slovenia'},
    {value: 'swe', label: 'Sweden'},
    {value: 'syr', label: 'Syrian Arab Republic'},
    {value: 'tcd', label: 'Chad'},
    {value: 'tgo', label: 'Togo'},
    {value: 'tha', label: 'Thailand'},
    {value: 'tjk', label: 'Tajikistan'},
    {value: 'tto', label: 'Trinidad and Tobago'},
    {value: 'tun', label: 'Tunisia'},
    {value: 'tur', label: 'Turkey'},
    {value: 'twn', label: 'Taiwan'},
    {value: 'tza', label: 'United Republic of Tanzania'},
    {value: 'uga', label: 'Uganda'},
    {value: 'ukr', label: 'Ukraine'},
    {value: 'ury', label: 'Uruguay'},
    {value: 'are', label: 'United Arab Emirates'},
    {value: 'usa', label: 'United States of America'},
    {value: 'uzb', label: 'Uzbekistan'},
    {value: 'vct', label: 'Saint Vincent and the Grenadines'},
    {value: 'ven', label: 'Venezuela'},
    {value: 'vnm', label: 'Vietnam'},
    {value: 'yem', label: 'Yemen'},
    {value: 'yug', label: 'Yogoslavia'},
    {value: 'other', label: 'Other'},    
]

export const disabilityListOption = [    
        {value:'none',label: 'No disibility specified'},
        {value:'albinism',label: 'Albinism'},
        {value:'adhd',label: 'Attention deficit disorder with/without hyperactivity (ADHD)'},
        {value:'autism',label: 'Autistic spectrum disorders'},
        {value:'behavioural',label: 'Behavioural / conduct disorder (including Sever behavioural problems)'},
        {value:'blindness',label: 'Blindness'},
        {value:'cerebral_palsy',label: 'Cerebral palsy'},
        {value:'deafness',label: 'Deafness'},
        {value:'dear_blindness',label: 'Deaf-blindness'},
        {value:'epilepsy',label: 'Epilepsy'},
        {value:'hard_hearing',label: 'Hard of hearing'},
        {value:'mild_intellectual_disability',label: 'Mild to moderate intellectual disability'},
        {value:'moderate_intellectual_disabiltiy' ,label: 'Moderate to severe/profound intellectual disability'},
        {value:'partial_sightedness',label: 'Partial sightedness / Low Vision'},
        {value:'physical_disability',label: 'Physical disabiity'},
        {value:'sever_intellectual_disability',label: 'Severely intellectually disabled'},
        {value:'learning_disability',label: 'Specific learning disability'},
        {value:'psychiatric_disorder',label: 'Psychiatric disorder'}
]

export const provinceOptions = [
    { value: 'Eastern-Cape', label: 'Eastern-Cape' },
    { value: 'Free-State', label: 'Free-State' },
    { value: 'Gauteng', label: 'Gauteng' },
    { value: 'KwaZulu-Natal', label: 'KwaZulu-Natal' },
    { value: 'Limpopo', label: 'Limpopo' },
    { value: 'Mpumalanga', label: 'Mpumalanga' },
    { value: 'Northern-Cape', label: 'Northern-Cape' },
    { value: 'North-West', label: 'North-West' },
    { value: 'Western-Cape', label: 'Western-Cape' },
]

export const raceOptions = [
    { value: 'african', label: 'African' },
    { value: 'asian', label: 'Asian' },
    { value: 'coloured', label: 'Coloured' },
    { value: 'indian', label: 'Indian' },
    { value: 'white', label: 'White' },
    { value: 'other', label: 'Other' }
]

export const genderOptions = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' }
]

export const titleOptions = [
    { value: 'Mr', label: 'Mr' },
    { value: 'Mrs', label: 'Mrs' },
    { value: 'Miss', label: 'Miss' },
    { value: 'Ms', label: 'Ms' },
    { value: 'Dr', label: 'Dr' },
    { value: 'Prof', label: 'Prof' }
]