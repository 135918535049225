import React from "react";
import { connect } from "react-redux";
import { setCount, setSubject } from "../store/actions";
import moment from "moment-timezone";
import $ from "jquery";

import "react-perfect-scrollbar/dist/css/styles.css";
import "react-custom-scroll/dist/customScroll.css";
import { Scrollbar } from "react-scrollbars-custom";

import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import QualificationHeadBox from "../components/course/QualificationHeadBox";
import SubjectListBox from "../components/course/SubjectListBox";
import ProgramContent from "../components/Program/ProgramContent";
import SkeletonSubject from "../loaders/SkeletonSubject";
import SkeletonQaulificationBox from "../loaders/SkeletonQualificationBox";
import SekletonSubjectList from "../loaders/SekletonSubjectList";

import {
  GetMySubjectsService,
  GetSubjectContentService,
} from "../services/QualificationService";
import { collect } from "underscore";
import PastSubjectList from "../components/course/PastSubjectList";


function mapDispatchToProps(dispatch) {
  return {
    setSubject: (payload) => dispatch(setSubject(payload)),
    setCount: (payload) => dispatch(setCount(payload)),
  };
}

class MyProgram extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSideBar:null,
      showProgress:false,
      responseData: [],
      subjects: [],
      completed_subjects: [],
      subjectContent: {},
      chat: {},
      loading: false,
      active: 0,
      initialClick: false,
      subjectId: 0,
      reload: 0,
      subjectListLoading: true,
      reduceCommentCount: 0,
      room_id: 0,
      isExamLaunched:false,
      assessmentId: 0,
      backToOverview : 0,

    };
    // this.showCompleted = React.createRef();
  }
  componentDidMount() {
    this.props.setCount(0);
    $("body").addClass("sub-sidebar-c");
    const { match } = this.props;

    if($("body").hasClass("sub-sidebar-c")){
      this.setState({showSideBar:true});
    }
    if(match?.params?.aid){
      this.setState({ assessmentId: match.params.aid });
    }
    this.setState({ loading: true });

    GetMySubjectsService(match.params)
    .then((res) => {
      if (res.data) {

        this.setState({ responseData: res.data });
  
        if (res.data?.subject?.others?.length <= 0 && res.data?.subject?.finished?.length > 0) {
          this.setState({ initialClick: true });
        }
  
        const initialID = match.url.includes("program") ? 0 : match.params?.sid;
        this.getSubContentMethod(initialID);
          this.setState({
            subjectId: initialID,
            subjects: res.data?.subject?.others,
            completed_subjects: res.data?.subject?.finised,
            subjectListLoading: false,
          });
        
  
        setTimeout(() => {
          this.setState({ loading: false });
        }, 1000);


      } else {
        this.handleRedirectToCourse();
      }
    })
    .catch((error) => {
      console.error("error", error);
      this.handleRedirectToCourse();
      this.setState({ subjectListLoading: false, loading: false });
    });
  
  

  //   if (this.props.location.pathname.includes("shortCourse")) {
  //     // GetMySubjectsService(match.params)
  //     // .then((res) => {
  //     //   debugger;

  //     //   if (
  //     //     res.data.subjects.others.length <= 0 &&
  //     //     res.data.subjects.finised.length > 0
  //     //   ) {
  //     //     this.setState({ initialClick: true });
  //     //   }
  //     //   let initialID;
  //     //   if (match.params.sid == undefined) {
  //     //     initialID =
  //     //       res.data.subjects.others.length > 0
  //     //         ? res.data.subjects.others[0].id
  //     //         : res.data.subjects.finised.length > 0
  //     //           ? res.data.subjects.finised[0].id
  //     //           : 58;
  //     //   } else {
  //     //     initialID = match.params.sid;

  //     //   }
      
  //       this.setState({ subjectId: match.params.sid });
  //       // this.setState({
  //       //   qualification: res.data.qualification,
  //       //   subjects: res.data.subjects.others,
  //       //   completed_subjects: res.data.subjects.finised,
  //       // });
  //       this.setState({ subjectListLoading: false });
  //       // setTimeout(() => {
  //         this.setState({ loading: false });
  //       // }, 1000);
  //     // })
  //     // .catch((error) => {
  //     //   this.setState({ subjectListLoading: false });
  //     // });
  //   } else {


  //   // $(".sub-detail-lft .sub-lft-body").mCustomScrollbar({
  //   //   theme: "dark-3",
  //   //   scrollButtons: {
  //   //     enable: true,
  //   //   },
  //   //   callbacks: {
  //   //     // onTotalScroll:function(){ addContent(this) },
  //   //     // onTotalScrollOffset:100,
  //   //     // alwaysTriggerOffsets:false
  //   //   },
  //   // });
  // 
}

  updateSubjectOnly = () => {    
    const { match } = this.props;    
    GetMySubjectsService(match.params)
      .then((res) => {
        if(res.data.qualification){
          this.setState({
            qualification: res.data,
            subjects: res.data.subject.others,
            completed_subjects: res.data.subject.finised,
          });          
        }else{
          this.handleRedirectToCourse()
        }
      })
      .catch((error) => {
        this.handleRedirectToCourse();
      });
  };

  updateSubject = (isSLP) => {
    
    const { match } = this.props;
    if (isSLP) {
      GetMySubjectsService({ qid: match.params.sid, isSLP, sp_student_intake_id: this.state?.subjectContent?.subject?.sp_student_intake_id })
        .then((res) => {
          this.setState({ subjectContent:  res.data });
        })
        .catch((err) =>  this.handleRedirectToCourse());
    } else {
      GetMySubjectsService(match.params)
        .then((res) => {
          if(res.data){
            this.setState({
              qualification: res.data,
              subjects: res.data.subject.others,
              completed_subjects: res.data?.subject?.finised,
            });
            GetSubjectContentService({ sid: this.state.subjectId })
              .then((res) => {
                this.setState({ subjectContent: res.data });
              })
              .catch((error) => {
                this.handleRedirectToCourse();
              });
          }else{
            this.handleRedirectToCourse()
          }
        })
        .catch((error) => {
          this.handleRedirectToCourse();
        });
    }
  };
  // shouldComponentUpdate() {
  //   return false;
  // }
  getSubContentMethod = (sid) => {
    //debugger;
    if (this.state.active !== sid && sid) {
      // this.state.qualification.school_type !== "shortcourse" && $("body").removeClass("hide-subject-sidebar");
      $("body").removeClass("hide-subject-sidebar");
      this.setState({showSideBar:false})
      this.setState({ subjectId: sid });
      GetSubjectContentService({ sid })
        .then((res) => {
          if(res.status==200){
            this.setState({ subjectContent: res.data });
            // this.setState({ active: sid });
            if (this.state.initialClick) {
              this.showCompleted.current.click();
              this.setState({ initialClick: false });
            }
          }else{
            this.handleRedirectToCourse()
          }
        })
        .catch((error) => {this.handleRedirectToCourse()});
    } else if (sid) {
      GetSubjectContentService({ sid })
        .then((res) => {
          if(res.status==200){
            this.setState({ subjectContent: res.data });
            this.setState({ active: sid });
            if (this.state.initialClick) {
              this.showCompleted.current.click();
              this.setState({ initialClick: false });
            }
          }else{
            this.handleRedirectToCourse()
          }
        })
        .catch((error) => {this.handleRedirectToCourse()});
    }
  };

  handleSideBar = () => {
    $("body").toggleClass("hide-subject-sidebar");
    if ($("body").hasClass("hide-subject-sidebar")) {
      this.setState({showSideBar:true})
      $("body").removeClass("sub-sidebar-c");
    } else {
      $("body").addClass("sub-sidebar-c");
      this.setState({showSideBar:false})
    }
  };


  reduceCount = (count, room_id) => {
    this.props.setCount(this.state.reduceCommentCount + count);
    this.setState({ reduceCommentCount: this.state.reduceCommentCount + count, room_id: room_id })

    let tempSub = this.state.subjects
    let comTempSub = this.state.completed_subjects

    tempSub.map(i => {
      if (i.id == room_id) {
        i.unreadMessage = i.unreadMessage - count
      }
    })
    comTempSub.map(i => {
      if (i.id == room_id) {
        i.unreadMessage = i.unreadMessage - count
      }
    })
    this.setState({
      subjects: tempSub,
      completed_subjects: comTempSub,
    });
  }

  handleRedirectToCourse(){
    this.props.history.push("/mycourses")
  }
  

  render() {
    return (
      <>
        <Header></Header>
        <div className="content-wrapper d-flex flex-wrap">
          <SideBar count={this.state.reduceCommentCount}></SideBar>
          <div className="sidebar-right">
            {this.state.subjectListLoading && <SekletonSubjectList />}
            {!this.state.subjectListLoading ? (
              <div className="subject-detail-wrap  h-100">
                {/* side bar */}
                {!this.props.location.pathname.includes("shortCourse") && <div className="sub-detail-lft bg-white">
                  <button
                    className="close-btn-sidebar"
                    onClick={this.handleSideBar}
                  >
                    <i className="close-bar close-bar-1"></i>
                    <i className="close-bar close-bar-2"></i>
                    <i className="close-bar close-bar-3"></i>
                  </button>
                  {this.state.loading && <SkeletonQaulificationBox />}
                  {!this.state.loading && (
                    <QualificationHeadBox row={this.state} showProgress={this.state.showProgress} setShowProgress={(value) => this.setState({ showProgress: value })} />
                  )}

                  {!this.state.loading && <h6 className='heading-title mx-4'>Courses</h6>}
                  <div className={`sub-lft-body ${this.state.showProgress ? 'max-height-575' : 'max-height-400'}`}>
                    <Scrollbar>
                      <div className="subject-list">
                        {this.state.loading && <SkeletonSubject />}
                  
                        {!this.state.loading && (
                          <>
                          <SubjectListBox
                            parentGetSubContentMethod={this.getSubContentMethod}
                            rows={this.state.subjects}
                            reload={this.state.reload}
                            active={this.state.active}
                            qid={this.props.match.params.qid}
                            isExamLaunched={this.state.isExamLaunched}
                            setIsExamLaunchedToTrue={() => this.setState({ isExamLaunched: true })}
                            setIsExamLaunchedToFalse={() => this.setState({ isExamLaunched: false })}
                            // changeSub={() => this.setState({ backToOverview: (this.state?.backToOverview) + 1 })}
                          />
                          <PastSubjectList
                            rows={this.state.completed_subjects}
                            active={this.state.active}
                            qid={this.props.match.params.qid}
                            changeSub={() => this.setState({ backToOverview: this.state.backToOverview + 1 })}
                          />
                          </ >
                        )}
                      </div>

                      {/* {
                        !this.state.loading && this.state.completed_subjects?.length > 0 ? (
                          <button
                            data-toggle="collapse"
                            data-target="#demo"
                            aria-expanded="false"
                            className="hide collapsed hide-completed"
                            ref={this.showCompleted}
                          >
                          </button>
                        ) : null
                      } */}


                        {/* <div className="subject-list collapse" id="demo">
                          <PastSubjectList
                            rows={this.state.completed_subjects}
                            active={this.state.active}
                            qid={this.props.match.params.qid}
                            changeSub={() => this.setState({ backToOverview: this.state.backToOverview + 1 })}
                          />
                        </div> */}

                      {/* <div id="spacer">&nbsp;</div> */}
                    </Scrollbar>
                  </div>
                </div>}
                {/* end of side bar */}
                <div className={this.props.location.pathname.includes("shortCourse") ? "sub-detail-full" : "sub-detail-rgt"}>
                  {this.state ? (
                    <ProgramContent
                      setShowSideBar={(value) => this.setState({ showSideBar: value })}
                      showSideBar={this.state.showSideBar}
                      completed_subject={this.state.completed_subjects}
                      subjectId={this.state.subjectId}
                      subjectList={this.state?.subjects}
                      isAdmin={0}
                      subjectContent={this.state.subjectContent}
                      qualification={this.state.responseData}
                      reduceCount={this.reduceCount}
                      isExamLaunched={this.state.isExamLaunched}
                      setIsExamLaunchedToTrue={() => this.setState({ isExamLaunched: true })}
                      setIsExamLaunchedToFalse={() => this.setState({ isExamLaunched: false })}
                      assessmentId={this.state.assessmentId}
                      qid={this.props.match.params.qid}
                      backToOverview={this.state.backToOverview}
                    />
                  )
                    : null}
                </div> 
              </div>) : !this.state.subjectListLoading ? (
                <div className="subject-no-content">
                  <div className="no-content">
                    <h4>Unauthorized Access</h4>
                    <p>You don't have access to view this page.</p>
                  </div>
                </div>) : ('')}
          </div>
        </div>
        </>
    
    );
  }
}

export default connect(null, mapDispatchToProps)(MyProgram);
