import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { download } from "../../utils/FileDownload";
import moment from "moment";
import randomColor from "randomcolor";
import $ from "jquery";
// import PuzzleImg from '../../assets/images/puzzle-icon.svg';
import { TrimText } from "../common/TrimText";
import { getSubjectStatus, subjectDuration } from "../../utils/commonFunction";
import MentoredSubject from "../course/MentoredSubject";
import ViewProfile from "../course/ViewProfile";
import ReactHtmlParser from "react-html-parser";
const stc = require("string-to-color");
const downloadFile = async (absolutePath, fileName) => {
  await download(absolutePath, fileName);
};

export default function SubjectGroupList({
  subjectList,
  qualification,
  qid,
  completed_subject,
  showSideBar,
  setShowSideBar
}) {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [sideBarShow, setSideBarShow]=useState(false)

  const [color, setColor] = useState(null);

  useEffect(() => {
    setColor(
      randomColor({
        luminosity: "dark",
      })
    );
  }, [qualification]);

  function createAbbreviation(name = "") {
    return (
      name.split(" ")[0].toString().toUpperCase().substring(0, 1) +
      (name.split(" ")[1]
        ? name.split(" ")[1].toString().toUpperCase().substring(0, 1)
        : "")
    );
  }

  useEffect(()=>{
    if(window.innerWidth < 769){
      setShowSideBar(false)
    }else{
      setShowSideBar(true)
    }
  },[])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    
    return () => window.removeEventListener("resize", handleResize);
  }, [showSideBar]);



  const getTextLength = () => {
    if (windowWidth < 321) {
      return 76; 
    }

    if (windowWidth < 376) {
      return 90; 
    }

    if (windowWidth < 426) {
      return 110; 
    }

    if (windowWidth < 769) {
      return 90; 
    }

    if (windowWidth < 1025) {
      return showSideBar ? 80 : 135; 
    }
  
    if (windowWidth < 1442) {
      return showSideBar ? 145 : 200; 
    }
  
    if (windowWidth < 1920) {
      return showSideBar ? 180 : 230; 
    }
  
    if (windowWidth < 2560) {
      return showSideBar ? 235 : 350; 
    }
  
    return 235 
  };

  return (
    <>
        <div className="row row-custome couses-newrw-lists">
            {subjectList?.map((ele, i) => {
              // if (ele?.subject_type === "shortcourse") {
              const icon = getSubjectStatus(ele?.icon);
              return (
                <div
                  className="col-lg-6 col-md-6 col-xs-12 qualification-box"
                  key={i}
                  title={ele.name}
                >
                  <div
                    className={`couses-renew-card home-bx-card qual-tab bg-white ${
                      ele?.subjectStatus === "Not Yet Published"
                        ? " short-course-disabled"
                        : ""
                    }`}
                  >
                    <>
                      {/* {ele.commentCount != 0 && (
                                  <div className="nav-tpFixed-comments" title="You have unread messages">
                                    <span className="nav-fa-comments">
                                      <small className="notify-count">{ele.commentCount || 0}</small>
                                      <i className="fal fa-comments"></i>
                                    </span>
                                  </div>
                                )} */}

                      

                      <div className="course-box w-100">
                        <div>
                        <div className="short-course-title">
                          <h6 className="tittle">{ele.code || "0"}</h6>
                          <div className="program-status">
                            <img src={icon} alt="" width="20px" height="20px" />
                            <p className="program-status-type">
                              {ele?.subjectStatus}
                            </p>
                          </div>
                        </div>

                        <div className="program-tittle">
                          <h2 title={ele.name || ""}>
                            {ele.name ? TrimText(ele.name, 50) : ""}
                          </h2>
                        </div>
                          
                          <div className="d-flex flex-direction-column align-items-center program-duration">
                            {ele.type === "Self-Paced" ? (
                              <p
                                className="months-duration"
                                style={{
                                  marginRight: "auto",
                                  fontSize: "12px",
                                }}
                              >
                                <span className="couse_i_in">
                                  <i className="fal fa-clock"></i>
                                </span>
                                {ele?.subject_duration} months
                              </p>
                            ) : (
                              <p
                                className="date-duration"
                                style={{
                                  marginRight: "auto",
                                  fontSize: "12px",
                                }}
                              >
                                <span className="couse_i_in">
                                  <i className="fal fa-calendar-alt"></i>
                                </span>
                                {ele.start
                                  ? moment
                                      .unix(ele.start)
                                      .local()
                                      .format("D MMM YYYY")
                                  : ""}{" "}
                                -{" "}
                                {ele?.end
                                  ? moment
                                      .unix(ele?.end)
                                      .local()
                                      .format("D MMM YYYY")
                                  : ""}{" "}
                                {subjectDuration(ele?.end, ele?.start)}
                              </p>
                            )}
                          </div>

                          <div className="qualification-view">
                            <ViewProfile
                              user={{
                                lead_photo: ele?.prog_lead_photo,
                                prog_lead_name: ele?.intake_coach_name,
                                lead_activity_status:
                                  ele.prog_lead_active == 1
                                    ? "online"
                                    : "offline",
                                lead_email: ele?.intake_coach_email,
                                lead_mobile: ele?.intake_coach_mobile,
                                supp_photo: ele?.intake_supp_photo,
                                supp_fullname: ele?.intake_supp_name,
                                supp_activity_status:
                                  ele.prog_supp_active == 1
                                    ? "online"
                                    : "offline",
                                supp_email: ele?.intake_supp_email,
                                supp_mobile: ele?.intake_supp_mobile,
                              }}
                              parentBox={false}
                            />
                          </div>
                          <div className="course-description">
                            {ele?.welcome
                              ? ele.welcome !== ""
                                ? TrimText(
                                    new DOMParser().parseFromString(
                                      ele.welcome,
                                      "text/html"
                                    ).body.textContent || "",
                                    getTextLength()
                                  )
                                : "NA"
                              : "NA"}
                          </div>
                          <div className="d-flex align-items-end justify-content-between">
                            <div className="progress-program progress-statistics">
                              <div className="program-stats">
                                <div className="program-card-progress">
                                  <span className="status">
                                    {" "}
                                    {ele?.progress?.progressBar}% completed
                                  </span>
                                  <div className="program-statistics-count-2">
                                    <ul className="num-info">
                                      {ele.progress.total_videos > 0 && (
                                        <li title="Videos" className="videoes">
                                          <i className="fal fa-video" />
                                          <span className="value">
                                            {ele.progress.total_videos}
                                          </span>
                                        </li>
                                      )}
                                      {ele.progress.total_quizs > 0 && (
                                        <li title="Quizzes" className="Quizzes">
                                          <i className="fal fa-clipboard-list" />
                                          <span className="value">
                                            {ele.progress.total_quizs}
                                          </span>
                                        </li>
                                      )}
                                      {ele.progress.total_documents > 0 && (
                                        <li title="Files" className="files">
                                          <i className="fal fa-file" />
                                          <span className="value">
                                            {ele.progress.total_documents}
                                          </span>
                                        </li>
                                      )}
                                      {ele.progress.total_assessment > 0 && (
                                        <li
                                          title="Assessment"
                                          className="files"
                                        >
                                          <i className="fal fa-ballot" />
                                          <span className="value">
                                            {ele.progress.total_assessment}
                                          </span>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                </div>
                                <div className="progress">
                                  <div
                                    className="progress-bar progress-bar-striped progress-bar-animated zero-progress"
                                    role="progressbar"
                                    style={{
                                      width: ele?.progress?.progressBar + "%",
                                    }}
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  >
                                    {ele?.progress?.progressBar}%
                                  </div>
                                </div>
                              </div>
                            </div>
                            <Link
                              className="btn btn-primary open-course"
                              to={`/course/${qid}/subject/${ele?.intake_id}`}
                            >
                              Open Course
                            </Link>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              );
            })}
            {/* //completed subject List  */}
            {completed_subject?.map((ele, i) => {
              const icon = getSubjectStatus(ele?.icon);
              return (
                <div
                className="col-lg-6 col-md-6 col-xs-12 qualification-box"
                key={i}
                title={ele.name}
              >
                <div
                  className={`couses-renew-card home-bx-card qual-tab bg-white ${
                    ele?.subjectStatus === "Not Yet Published"
                      ? " short-course-disabled"
                      : ""
                  }`}
                >
                  <>
                    {/* {ele.commentCount != 0 && (
                                <div className="nav-tpFixed-comments" title="You have unread messages">
                                  <span className="nav-fa-comments">
                                    <small className="notify-count">{ele.commentCount || 0}</small>
                                    <i className="fal fa-comments"></i>
                                  </span>
                                </div>
                              )} */}

                    

                    <div className="course-box w-100">
                      <div>
                      <div className="short-course-title">
                        <h6 className="tittle">{ele.code || "0"}</h6>
                        <div className="program-status">
                          <img src={icon} alt="" width="20px" height="20px" />
                          <p className="program-status-type">
                            {ele?.subjectStatus}
                          </p>
                        </div>
                      </div>

                      <div className="program-tittle">
                        <h2 title={ele.name || ""}>
                          {ele.name ? TrimText(ele.name, 50) : ""}
                        </h2>
                      </div>
                        
                        <div className="d-flex flex-direction-column align-items-center program-duration">
                          {ele.type === "Self-Paced" ? (
                            <p
                              className="months-duration"
                              style={{
                                marginRight: "auto",
                                fontSize: "12px",
                              }}
                            >
                              <span className="couse_i_in">
                                <i className="fal fa-clock"></i>
                              </span>
                              {ele?.subject_duration} months
                            </p>
                          ) : (
                            <p
                              className="date-duration"
                              style={{
                                marginRight: "auto",
                                fontSize: "12px",
                              }}
                            >
                              <span className="couse_i_in">
                                <i className="fal fa-calendar-alt"></i>
                              </span>
                              {ele.start
                                ? moment
                                    .unix(ele.start)
                                    .local()
                                    .format("D MMM YYYY")
                                : ""}{" "}
                              -{" "}
                              {ele?.end
                                ? moment
                                    .unix(ele?.end)
                                    .local()
                                    .format("D MMM YYYY")
                                : ""}{" "}
                              {subjectDuration(ele?.end, ele?.start)}
                            </p>
                          )}
                        </div>

                        <div className="qualification-view">
                          <ViewProfile
                            user={{
                              lead_photo: ele?.prog_lead_photo,
                              prog_lead_name: ele?.intake_coach_name,
                              lead_activity_status:
                                ele.prog_lead_active == 1
                                  ? "online"
                                  : "offline",
                              lead_email: ele?.intake_coach_email,
                              lead_mobile: ele?.intake_coach_mobile,
                              supp_photo: ele?.intake_supp_photo,
                              supp_fullname: ele?.intake_supp_name,
                              supp_activity_status:
                                ele.prog_supp_active == 1
                                  ? "online"
                                  : "offline",
                              supp_email: ele?.intake_supp_email,
                              supp_mobile: ele?.intake_supp_mobile,
                            }}
                          />
                        </div>
                        <div className="course-description">
                          {ele?.welcome
                            ? ele.welcome !== ""
                              ? TrimText(
                                  new DOMParser().parseFromString(
                                    ele.welcome,
                                    "text/html"
                                  ).body.textContent || "",
                                  getTextLength()
                                )
                              : "NA"
                            : "NA"}
                        </div>
                        <div className="d-flex align-items-end justify-content-between">
                          <div className="progress-program progress-statistics">
                            <div className="program-stats">
                              <div className="program-card-progress">
                                <span className="status">
                                  {" "}
                                  {ele?.progress?.progressBar}% completed
                                </span>
                                <div className="program-statistics-count-2">
                                  <ul className="num-info">
                                    {ele.progress.total_videos > 0 && (
                                      <li title="Videos" className="videoes">
                                        <i className="fal fa-video" />
                                        <span className="value">
                                          {ele.progress.total_videos}
                                        </span>
                                      </li>
                                    )}
                                    {ele.progress.total_quizs > 0 && (
                                      <li title="Quizzes" className="Quizzes">
                                        <i className="fal fa-clipboard-list" />
                                        <span className="value">
                                          {ele.progress.total_quizs}
                                        </span>
                                      </li>
                                    )}
                                    {ele.progress.total_documents > 0 && (
                                      <li title="Files" className="files">
                                        <i className="fal fa-file" />
                                        <span className="value">
                                          {ele.progress.total_documents}
                                        </span>
                                      </li>
                                    )}
                                    {ele.progress.total_assessment > 0 && (
                                      <li
                                        title="Assessment"
                                        className="files"
                                      >
                                        <i className="fal fa-ballot" />
                                        <span className="value">
                                          {ele.progress.total_assessment}
                                        </span>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              </div>
                              <div className="progress">
                                <div
                                  className="progress-bar progress-bar-striped progress-bar-animated zero-progress"
                                  role="progressbar"
                                  style={{
                                    width: ele?.progress?.progressBar + "%",
                                  }}
                                  aria-valuenow="25"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  {ele?.progress?.progressBar}%
                                </div>
                              </div>
                            </div>
                          </div>
                          <Link
                            className="btn btn-primary open-course"
                            to={`/course/${qid}/subject/${ele?.intake_id}`}
                          >
                            Open Course
                          </Link>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </div>
              );
            })}
          </div>
    </>
  );
}
