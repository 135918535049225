import React, { useState, useEffect, useRef, lazy, Suspense, Children } from "react";
import SideBar from "../components/common/SideBar";
import {
  GetMyPastQualificationService,
  GetMyQualificationService,
  GetShortCourseQualification,
} from "../services/QualificationService";
import SkeletonQaulification from "../loaders/SkeletonQualification";
import QualificationBox from "../components/course/QualificationBox";
import { getCurrentTimeHomePage } from "../components/common/Helper";
import { UpdateStudentTimeZone } from "../services/CalendarService";
import PastQualificationBox from "../components/course/PastQualificationBox";
import Header from "../components/common/Header";

const MyCourses = () => {
  const [isPast, setIsPast] = useState(0);
  const [qualifications, setQualifications] = useState([]);
  const [pastQualification, setPastQualification] = useState([]);
  const [shortCourse, setShortCourse] = useState([]);
  const [pastShortCourse, setPastShortCourse]=useState([])
  const [loading, setLoading] = useState(true);
  const [showQual, setShowQual] = useState(true);
  const [totalQual, setTotalQual] = useState(0);
  const [totalSCourses, setTotalSCourses] = useState(0);
  const [showTabs, setShowTabs] = useState(false);
  const [error, setError] = useState(null);
  const [showQualification, setShowQualification]=useState(false)
  const [showShortCourse, setShowShortCourse]=useState(false)
  useEffect(() => {
    const fetchQualifications = async () => {
      setLoading(true);
      setError(null); // Reset error state on new fetch
      try {
        const resCurrent = await GetMyQualificationService({ isPast: 0 });

        if(resCurrent.data?.data.length > 0){
          setQualifications(resCurrent.data?.data || []);
          setTotalQual(resCurrent.data?.data.length)
          setShowQualification(true)
        }
        if(resCurrent.data?.isShortCoursesExists > 0){
          setShowShortCourse(true)
        }    

        if(resCurrent.data?.data.length < 1){
          setShowQual(false)
        }
        setShowTabs(true);

      } catch (error) {
        console.error(error);
        setError("Failed to load qualifications.");
      } finally {
        setLoading(false); // Ensure loading is set to false after the operation
      }
  
      try {
        const resPast = await GetMyPastQualificationService({ isPast: 1 });
        setPastQualification(resPast.data?.data || []);
      } catch (error) {
        console.error(error);
        setError("Failed to load past qualifications.");
      }
    };
  
    const fetchShortCourses = async () => {
      setLoading(true);
      setError(null); // Reset error state on new fetch
      try {
        const res = await GetShortCourseQualification();
        const response = res?.data || [];
  
        const newShortCourses = response.filter(item => !item.isPast);
        const newPastShortCourses = response.filter(item => item.isPast);
  
        setShortCourse(newShortCourses);
        setPastShortCourse(newPastShortCourses);
      } catch (error) {
        console.error("Error fetching short courses: ", error);
        setError("Failed to load short courses.");
      } finally {
        setLoading(false); // Ensure loading is set to false after the operation
      }
    };
  
    const updateStudentTimeZone = async () => {
      try {
        const res = await UpdateStudentTimeZone({
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
        if (res.status === 200) {
          // Handle success if needed
        }
      } catch (err) {
        console.log(err);
      }
    };
  
    if (showQual) {
      fetchQualifications();
    } else {
      fetchShortCourses();
    }
  
    updateStudentTimeZone();
  }, [showQual]);
  


  return (
    <>
        <Header />
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar />
        <div className="sidebar-right">
          <div className="mycourses-list-wrap mycourses-edit tab-sec">
            <div className="my-tickets-heading-blk flex-direction mb-4">
              <h4 className="text-left-align landing-heading heading_color_dynamic my-courses-title">
                My <span> Courses</span>
              </h4>
              <div className="text-md-right">
                <p className=""></p>
              </div>
              <div className="lecture-tabs-wrap lecture-time-box">
                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                  {showTabs && (
                    <>
                      {showQualification && <li className={`nav-item ${showQual ? 'active' : ''}`} title="Qualifications">
                        <a
                          id="pills-Posts-tab"
                          data-toggle="pill"
                          href="#pills-Posts"
                          role="tab"
                          aria-controls="pills-Posts"
                          aria-selected="true"
                          className="nav-link active"
                          onClick={() => setShowQual(true)}
                        >
                          <i className="far fa-graduation-cap" aria-hidden="true"></i>
                          Qualifications
                          {totalQual !== 0 && (
                            <div className="nav-fa-comments my-crs-head" title="You have unread messages">
                              <small className="notify-count">{totalQual < 10 ? totalQual : "9+"}</small>
                              <i className="fal fa-comments"></i>
                            </div>
                          )}
                        </a>
                      </li>}

                     {showShortCourse && <li className={`nav-item`} title="Short Courses">
                        <a
                          className={`nav-link ${!showQual ? 'active' : ''}`}
                          id="pills-Overview-tab"
                          data-toggle="pill"
                          href="#pills-Overview"
                          role="tab"
                          aria-controls="pillsOverview"
                          aria-selected="false"
                          onClick={()=>setShowQual(false)}
                        >
                          <i className="fal fa-award"></i>
                          Short Courses
                          {totalSCourses !== 0 && (
                            <div className="nav-fa-comments my-crs-head" title="You have unread messages">
                              <small className="notify-count">{totalSCourses}</small>
                              <i className="fal fa-comments"></i>
                            </div>
                          )}
                        </a>
                      </li>}
                    </>
                  )}
                </ul>
                <p className="date-time"> {getCurrentTimeHomePage(Date().toLocaleString())}</p>
              </div>
            </div>

            {loading && <SkeletonQaulification />}
  
            {(!loading && ((qualifications?.length > 0 && showQual) || showShortCourse)) &&  (
              <QualificationBox
                showQualification={showQual}
                qualification={showQual ? qualifications : shortCourse}
                showProgram={true}
                state={{ isPast, qualifications, pastQualification, shortCourse, loading, showQual, totalQual, totalSCourses, showTabs }}
              />
            )}
            {(!loading && (pastQualification?.length > 0 && showQual) || (pastShortCourse?.length > 0 && !showQual)) ?
              <>
                <button
                  data-toggle="collapse"
                  data-target="#show-hide-past-qualification"
                  aria-expanded="false"
                  className={`hide collapsed ${showQual ? "hide-completed-program" : "hide-completed-course"}`}
                ></button> 

                <div className="collapse" id="show-hide-past-qualification">
                  <PastQualificationBox
                    state={{ isPast, qualifications, pastQualification, pastShortCourse, loading, showQual, totalQual, totalSCourses, showTabs }}
                  />
                </div>
              </>
              : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyCourses;
