import React, { useState, useEffect, useRef, useCallback, memo } from "react";
import VideoPlayer from "./VidoePlayer";
import QuizeImage from "../../assets/images/ic-1.png";
import {
  getAssembliesItem,
  getAssembliesItemAdmin,
  fileTrackingService,
  } from "../../services/AssembliesService";
import SideSubAssembliesItems from "./SideSubAssembliesItems";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { UpdateLessonType, updateCurrentActivity, updateCurrentAssembly } from "../../store/actions";
import { TrimText } from "../common/TrimText";

let obj = {};

function SideAssemliesItems(props) {
  const {
    assemblyItem,
    lesson,
    setshow,
    setrId,
    getSubContentMethod,
    subassembliesItems,
    setSubassembliesItems,
    lessonindex,
    isAdmin,
    refreshProgress,
    match,
    setLoading,
    setModuleId,
    buttonStatusCheck
  } = props;
 
  const [video_duration_time, setVideo_duration_time]=useState("")
  const [mounted, setMounted] = useState(false);
  const dispatch= useDispatch()
  const [buttonCheckedStatus1, setButtonCheckedStatus1] = useState(0);
  const [documentId1, setDocumentId1] = useState([]);
  const [currentVideoId1, setCurrentVideoId1] = useState(0);
  const currentAssembly = useSelector(state => state.learningMaterial.currentAssembly)
  const currentActivity=useSelector(state=>state.learningMaterial.current_activity)
  const lesson_type = useSelector(state => state.learningMaterial.active_lesson_type);
  const setCustomeCurrentVideoId = (videoId) => {
    setCurrentVideoId1(videoId);
  };
  const hasRun = useRef(false);

  // const handleFileTracking = (itemId) => {  
  //   fileTrackingService(itemId).then((res) => {
  //     setButtonCheckedStatus1(true);
  //     setDocumentId1([...documentId1, itemId]);
  //     props.parentGetLearningMaterialData(props.parentSubId);
  //     props.refreshProgress(props.lesson.module_id);
  //   });
  //   getSubContentMethod();
  // };

  const handleSubCategoryLessons=(item, item_type)=>{
    if(currentActivity !== item) {
      dispatch(updateCurrentActivity(item))
      dispatch(UpdateLessonType(item_type))
    }
  }
  
  const formatDuration = (duration) => {
    const parts = duration.match(/(\d+)([mhms])/g);
    let seconds = 0;
  
    parts.forEach(part => {
      const value = parseInt(part.match(/\d+/)[0], 10);
      const unit = part.match(/[mhms]/)[0];
  
      switch (unit) {
        case 'h':
          seconds += value * 3600;
          break;
        case 'm':
          seconds += value * 60;
          break;
        case 's':
          seconds += value;
          break;
        default:
          break;
      }
    });
  
    return moment.utc(seconds * 1000).format('mm:ss');
  };

  useEffect(() => {
    if(assemblyItem?.video_duration){
    const formattedTime = formatDuration(assemblyItem?.video_duration);
    setVideo_duration_time(formattedTime)}
  }, [assemblyItem?.video_duration])

  const handleOpenLesson = (assemblyItem) => {
    dispatch(updateCurrentActivity(assemblyItem.item_id))
    dispatch(UpdateLessonType(assemblyItem.item_type))
    // if(currentAssembly !==assemblyItem.assembly_id){
    //   dispatch(updateCurrentAssembly(assemblyItem.assembly_id))
    // }
  }

  useEffect(() => {
    if (currentActivity && lesson_type === 3) {
      let element = document.getElementById(currentActivity);
      let allEle = document.querySelectorAll(".learing-sub-assembly");
      let allParent = document.querySelectorAll(".sub-assembly-item");
      let childEle = document.querySelector(`#accordionBodyOne${currentActivity}`);
      
      if (element && childEle && allEle.length > 0 && allParent.length > 0) {
        allEle.forEach(ele => {
          ele.classList.remove("show");
        });
        allParent.forEach(ele => {
          ele.setAttribute("aria-expanded", false);
        });
        element.setAttribute("aria-expanded", true);
        childEle.classList.add("show");
      }
    }
  }, [currentActivity, lesson_type]);
   
  return ( 
    <>
      <li key={lesson.item_id}>
        <div className={"sub-body" + (lesson.item_type === 2 ? ' video-sec' : '')}>
          {assemblyItem.item_type === 2 ? (
            <div
              className={`lesson-card ${assemblyItem.item_id == currentActivity ? "lesson-active" : ""}`}
              onClick={(e) => handleOpenLesson(assemblyItem)} title={assemblyItem.item_name}>   
              <div className="card-media-text d-flex">
                <span className="assembly-input-span"><input type="checkbox" className="assembly-check" checked={assemblyItem?.is_video_watched}/></span>
                <div className="">
                  <p>{assemblyItem.item_name.includes(": ") ? assemblyItem.item_name.split(": ")[1] : assemblyItem.item_name}</p>
                  <p className="document-detail">{video_duration_time ? video_duration_time : "NA"}<span className="document-document-icon"><i className="fal fa-play"></i></span></p>
                </div>
              </div>
              <p className="assets">
              <i title="Video" className="fal fa-video"></i>
              </p>
            </div>
          ) : null}


          {assemblyItem.item_type === 7  ? (
             <div className={`lesson-card ${assemblyItem.item_id == currentActivity ? "lesson-active" : ""}`}
             onClick={(e) => handleOpenLesson(assemblyItem)} title={assemblyItem.item_name}>   
              <div className="card-media-text d-flex">
                <span className="assembly-input-span"><input type="checkbox" className="assembly-check"   checked={buttonStatusCheck || assemblyItem?.buttonCheckedStatus}/></span>
                <div className="">
                  <p>{assemblyItem.item_name.includes(": ") ? assemblyItem.item_name.split(": ")[1] : assemblyItem.item_name}</p>
                </div>
              </div>
              {/* <p className="assets">
                <i className="fal fa-ballot"></i>
              </p> */}
            </div>
          ) : null}

          {assemblyItem.item_type === 4 ? (
           <div className={`lesson-card ${assemblyItem.item_id == currentActivity ? "lesson-active" : ""}`}
           onClick={(e) => handleOpenLesson(assemblyItem)} title={assemblyItem.item_name}>   
              <div className="card-media-text d-flex" >
                <span className="assembly-input-span"><input type="checkbox" className="assembly-check" checked={assemblyItem?.buttonCheckedStatus}/></span>
                <div className="">
                  <p>{assemblyItem.item_name.includes(": ") ? assemblyItem.item_name.split(": ")[1] : assemblyItem?.item_name}</p>
                  <p className="document-detail"><span className="document-size">{assemblyItem?.document_size ? `${assemblyItem?.document_size}`  : "NA"}</span><span className="document-document-icon"><i className="fal fa-download"></i></span></p>
                </div>
              </div>
              <div className="assets">
                <i title="Document" className="fal fa-file-pdf"></i>
              </div>
            </div>
          ) : null}

          {assemblyItem.item_type === 3 ? (
            <div id={"accord-parent" + lesson.module_id} title={assemblyItem.item_name}>
              {!match.path.includes("shortCourse") && (
                <div className="assemblyItem-head-top">
                  <span className="assemblyItem-top-icon">
                    <i title="plus" className="fal fa-info-circle" />
                  </span>
                  <p className="assemblyItem-label">
                    {TrimText(assemblyItem?.item_name || "NA", 25)}
                  </p>
                </div>
              )}
              <div className="learning-material-card" key={"learning-material-card" + assemblyItem?.item_id}>
                <div
                  className="hd-main d-flex sub-assembly-item flex-wrap"
                  id={"accord-sub" + assemblyItem.item_id}
                  onClick={() => handleSubCategoryLessons(assemblyItem?.item_id, assemblyItem?.item_type)}
                  data-toggle="collapse"
                  data-target={"#accordionBody" + assemblyItem.item_id}
                  aria-controls={"accordionBody" + assemblyItem.item_id}
                  aria-expanded="false"
                >
                  <div className="learning-material-head borderprimary">
                    <div className="learning-left">
                      <h3>{assemblyItem.item_name}</h3>
                      <div className="header-status">
                        <div className="status-gol">
                          <i className="fal fa-chevron-down"></i>
                        </div>
                      </div>
                    </div>
                    <div className="learning-right">
                      <ul className="learningright-inner d-flex align-items-center">
                        <li className="material-info deep-text">
                          <i title="Videos" className="fal fa-video" />
                          {assemblyItem.assembliesVidoesFile}
                          <i title="Documents" className="fal fa-file" />
                          {assemblyItem.assembliesDocumentFile}
                          <i title="Quiz" className="fal fa-clipboard-list" />
                          {assemblyItem.assembliesQuiz}
                          <i title="Link" className="fal fa-external-link" />
                          {assemblyItem.assembliesLinkFile}
                        </li>
                      </ul>
                    </div>
                    <div className="course-item-progress">
                      <div className="progress" style={{ height: "11px" }}>
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: assemblyItem.progressPercentag + "%" }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {assemblyItem.progressPercentag ? assemblyItem.progressPercentag : "0"}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="learning-material-body learing-sub-assembly collapse"
                  id={"accordionBody" + assemblyItem.item_id}
                  data-parent={"#accord-parent" + lesson.module_id}
                  aria-labelledby={"accord-sub" + assemblyItem.item_id}
                >
                  <ul className="sub-ul">
                    {subassembliesItems.length
                      ? subassembliesItems.map((assemblyItem1, index1) => (
                        <SideSubAssembliesItems
                          isAdmin={isAdmin}
                          key={index1}
                          lesson={lesson}
                          assemblyItem={assemblyItem1}
                          buttonStatusCheck={buttonStatusCheck}
                        />
                      ))
                      : null}
                  </ul>
                </div>
              </div>
            </div>
          ) : null}


          {assemblyItem.item_type === 5 ? (
            <div className={`lesson-card ${assemblyItem.item_id == currentActivity ? "lesson-active" : ""}`}
            onClick={(e) => handleOpenLesson(assemblyItem)} title={assemblyItem.item_name}>  
                <div className="card-media-text d-flex" >
                <span className="assembly-input-span"><input type="checkbox" className="assembly-check" checked={assemblyItem?.buttonCheckedStatus}/></span>
                <div className="">
                  <p>{assemblyItem.item_name.includes(": ") ? assemblyItem.item_name.split(": ")[1] : assemblyItem.item_name}</p>
                </div>
              </div>
              <div className="assets">
              <i title="Link" className="fal fa-external-link"></i>
              </div>
            </div>
          ) : null}

          {assemblyItem.item_type === 6 ? (
            <div className={`lesson-card ${assemblyItem.item_id == currentActivity ? "lesson-active" : ""}`}
            onClick={(e) => handleOpenLesson(assemblyItem)} title={assemblyItem.item_name}>   
              <div className="card-media-text d-flex" >
                <span className="assembly-input-span"><input type="checkbox" className="assembly-check" checked={assemblyItem?.buttonCheckedStatus}/></span>
                <div className="">
                  <p>{assemblyItem.item_name.includes(": ") ? assemblyItem.item_name.split(": ")[1] : assemblyItem.item_name}</p>
                </div>
              </div>
              <div className="assets">
               <i title="Quiz" class="fal fa-clipboard-list ml-2"></i>
              </div>
            </div>
          ) : null}
        </div>
      </li>
    </>
  );
}

export default memo(SideAssemliesItems)